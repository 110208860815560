import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="view-only-role-check-boxes"
export default class extends Controller {
  static targets = ['checkBox'];

  toggle(e) {
    const viewOnly = e.target.dataset.viewOnly === 'true';
    const shouldDisable = viewOnly && e.target.checked;

    this.checkBoxTargets.forEach((cb) => {
      if (cb.dataset.viewOnly !== 'true') {
        cb.disabled = shouldDisable;
      }
    });
  }
}
