import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

// Connects to data-controller="collapse-radio-action"
export default class extends Controller {
  show(e) {
    const targetElement = document.querySelector(e.target.dataset.bsTarget);
    const collapseInstance = new bootstrap.Collapse(targetElement, {
      toggle: false,
    });
    collapseInstance.show();
  }

  hide(e) {
    const targetElement = document.querySelector(e.target.dataset.bsTarget);
    const collapseInstance = new bootstrap.Collapse(targetElement, {
      toggle: false,
    });
    collapseInstance.hide();
  }
}
