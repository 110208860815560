import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

// Connects to data-controller="basket-watcher"
export default class extends Controller {
  static values = {
    reloadPath: String,
    bookingRequestId: String,
  };

  initialize() {
    this.subscribe();
  }

  subscribe = () => {
    consumer.subscriptions.create(
      { channel: 'BasketWatcherChannel', id: this.bookingRequestIdValue },
      {
        connected: () => {
          // Called when the subscription is ready for use on the server
        },

        disconnected: () => {
          // Called when the subscription has been terminated by the server
        },

        received: () => {
          // Called when there's incoming data on the websocket for this channel
          this.showOverlay();
          window.location.reload();
        },
      }
    );
  };

  showOverlay() {
    document.getElementById('basket-updated-overlay').classList.add('active');
  }
}
